// src/services/api-service.js

import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';

// Use API Gateway URL
const API_BASE_URL = 'https://pr6ipjk6h7.execute-api.us-east-1.amazonaws.com';

class ApiService {
  static async getHeaders() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();
      const companyId = userAttributes['custom:company_id'];

      return {
        'Authorization': `Bearer ${session.tokens.accessToken.toString()}`,
        'Content-Type': 'application/json',
        'company-id': companyId || '1',
        'godmode': 'true',
        'mode': 'cors',
      };
    } catch (error) {
      console.error('Error getting headers:', error);
      return {
        'Content-Type': 'application/json',
        'company-id': '1',
        'godmode': 'true',
        'mode': 'cors',
      };
    }
  }

  static async makeRequest(path, options = {}) {
    try {
      const headers = await this.getHeaders();
      const requestOptions = {
        ...options,
        headers: { ...headers, ...options.headers },
        mode: 'cors',
      };

      // Ensure path starts with /
      const cleanPath = path.startsWith('/') ? path : `/${path}`;
      const url = `${API_BASE_URL}${cleanPath}`;

      console.log('Making request:', {
        url,
        method: options.method,
        headers: requestOptions.headers
      });

      const response = await fetch(url, requestOptions);
      
      console.log('Response received:', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries())
      });

      return response;
    } catch (error) {
      console.error('Request failed:', error);
      throw error;
    }
  }

  static async get(path, customHeaders = {}) {
    const response = await this.makeRequest(path, {
      method: 'GET',
      headers: customHeaders,
    });
    return this.handleResponse(response);
  }

  static async post(path, data, customHeaders = {}) {
    const response = await this.makeRequest(path, {
      method: 'POST',
      headers: customHeaders,
      body: JSON.stringify(data)
    });
    return this.handleResponse(response);
  }

  static async put(path, data, customHeaders = {}) {
    const response = await this.makeRequest(path, {
      method: 'PUT',
      headers: customHeaders,
      body: JSON.stringify(data)
    });
    return this.handleResponse(response);
  }

  static async delete(path, customHeaders = {}) {
    const response = await this.makeRequest(path, {
      method: 'DELETE',
      headers: customHeaders
    });
    return this.handleResponse(response);
  }

  static async handleResponse(response) {
    try {
      const contentType = response.headers.get('content-type');
      console.log('Handling response:', {
        status: response.status,
        statusText: response.statusText,
        contentType
      });

      if (!response.ok) {
        let errorMessage = `HTTP error! status: ${response.status}`;
        if (contentType?.includes('application/json')) {
          const error = await response.json();
          errorMessage = error.message || errorMessage;
        } else {
          errorMessage = await response.text() || errorMessage;
        }
        throw new Error(errorMessage);
      }

      if (contentType?.includes('application/json')) {
        const data = await response.json();
        console.log('Response data:', data);
        return data;
      }

      const text = await response.text();
      console.log('Response text:', text);
      return text;
    } catch (error) {
      console.error('Error handling response:', error);
      throw error;
    }
  }
}

export default ApiService;