import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp, updateUserAttributes, getCurrentUser } from '@aws-amplify/auth';
import { endpointsApi } from '../services/endpoints-api';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '', // Added company name field
    timezone: '',
    mfaPreference: 'none',
    companyId: 1,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setFormData(prevState => ({
      ...prevState,
      timezone: detectedTimezone,
      companyId: 1,
    }));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const createCompany = async () => {
    try {
      // Create company using your API
      const response = await fetch('https://api.observant.zerosandones.us/companies', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.companyName,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create company');
      }

      const data = await response.json();
      return data.companyId; // Assuming the API returns the company ID
    } catch (error) {
      console.error('Error creating company:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      // Create the user account
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: formData.username,
        password: formData.password,
        options: {
          userAttributes: {
            email: formData.email,
            zoneinfo: formData.timezone,
          },
          autoSignIn: {
            enabled: true,
          },
        },
      });

      if (isSignUpComplete) {
        // Create company and get company ID
        const companyId = await createCompany();

        // Update user attributes with company ID
        await updateUserAttributes({
          userAttributes: {
            'custom:company_id': companyId,
            'custom:company_name': formData.companyName,
            'custom:mfa_preference': formData.mfaPreference,
          }
        });

        // Handle MFA setup if needed
        if (formData.mfaPreference !== 'none') {
          navigate('/mfa-setup', { 
            state: { 
              username: formData.username,
              mfaPreference: formData.mfaPreference 
            } 
          });
        } else {
          navigate('/dashboard');
        }
      } else {
        // Handle email verification if needed
        navigate('/confirm-signup', { 
          state: { 
            username: formData.username, 
            email: formData.email,
            mfaPreference: formData.mfaPreference,
          } 
        });
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(err.message || 'Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">Create an Account</h1>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="username" className="block mb-2 text-gray-800 dark:text-gray-200">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-gray-800 dark:text-gray-200">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>

        {/* Add Company Name field */}
        <div className="mb-4">
          <label htmlFor="companyName" className="block mb-2 text-gray-800 dark:text-gray-200">Company Name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block mb-2 text-gray-800 dark:text-gray-200">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-2 text-gray-800 dark:text-gray-200">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>

        <div className="mb-6">
          <label htmlFor="mfaPreference" className="block mb-2 text-gray-800 dark:text-gray-200">MFA Preference</label>
          <select
            id="mfaPreference"
            name="mfaPreference"
            value={formData.mfaPreference}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
          >
            <option value="none">No MFA</option>
            <option value="totp">Google Authenticator</option>
          </select>
        </div>

        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Registering...' : 'Register'}
        </button>
      </form>
    </div>
  );
};

export default Register;