import React, { useState, useEffect } from 'react';
import { getCurrentUser, fetchUserAttributes } from '@aws-amplify/auth';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();
        setUser({ ...currentUser, attributes: userAttributes });
      } catch (error) {
        console.error('Error fetching user', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (loading) {
    return <div className="text-center py-12">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Dashboard</h1>
      {user && (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Welcome, {user.attributes.email}!</h2>
          <p className="text-gray-700 dark:text-gray-300 mb-4">Here's your account overview:</p>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300">
            <li>Email: {user.attributes.email}</li>
            <li>User ID: {user.username}</li>
            <li>Email Verified: {user.attributes.email_verified ? 'Yes' : 'No'}</li>
          </ul>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link to="/endpoints" className="bg-blue-600 text-white py-4 px-6 rounded-lg text-center hover:bg-blue-700 transition duration-300">
          Manage Endpoints
        </Link>
        <Link to="/account" className="bg-green-600 text-white py-4 px-6 rounded-lg text-center hover:bg-green-700 transition duration-300">
          Account Settings
        </Link>
        <Link to="/analytics" className="bg-purple-600 text-white py-4 px-6 rounded-lg text-center hover:bg-purple-700 transition duration-300">
          View Analytics
        </Link>
        <Link to="/billing" className="bg-yellow-600 text-white py-4 px-6 rounded-lg text-center hover:bg-yellow-700 transition duration-300">
          Billing and License Information
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;