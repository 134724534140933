import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { setUpTOTP, verifyTOTPSetup } from 'aws-amplify/auth';
import { QRCodeSVG } from 'qrcode.react';
import awsExports from '../config/aws-exports';

Amplify.configure(awsExports);

const AccountManagement = ({ signOut, user }) => {
  const [userAttributes, setUserAttributes] = useState({});
  const [loading, setLoading] = useState(true);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [showTotpSetup, setShowTotpSetup] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [totpSecret, setTotpSecret] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const attributes = await fetchUserAttributes();
      setUserAttributes(attributes);
      setMfaEnabled(attributes['custom:mfa_preference'] === 'totp');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user attributes', error);
      setLoading(false);
    }
  };

  const handleError = (error, operation) => {
    console.error(`Error during ${operation}:`, error);
    let errorMessage = `Failed to ${operation}. `;
    if (error.code === 'NotAuthorizedException') {
      errorMessage += 'Please check your credentials and try again.';
    } else if (error.code === 'InvalidParameterException') {
      errorMessage += 'Invalid input. Please check your entries and try again.';
    } else {
      errorMessage += error.message || 'An unknown error occurred.';
    }
    setError(errorMessage);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      await updateUserAttributes({
        userAttributes: {
          name: userAttributes.name,
          email: userAttributes.email,
          'custom:organization': userAttributes['custom:organization'],
          'custom:position': userAttributes['custom:position'],
        }
      });
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile', error);
      setError('Failed to update profile');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserAttributes(prevAttributes => ({
      ...prevAttributes,
      [name]: value
    }));
  };

  const handleToggleMfa = async () => {
    if (mfaEnabled) {
      try {
        await updateUserAttributes({
          userAttributes: {
            'custom:mfa_preference': 'none',
          }
        });
        setMfaEnabled(false);
        alert('MFA disabled successfully');
      } catch (error) {
        console.error('Error disabling MFA', error);
        setError('Failed to disable MFA');
      }
    } else {
      setShowTotpSetup(true);
      setupTOTP();
    }
  };

  const setupTOTP = async () => {
    try {
      const { secretKey, issuer, username } = await setUpTOTP();
      console.log('TOTP setup result:', { secretKey, issuer, username });
      setTotpSecret(secretKey);
      const encodedIssuer = encodeURIComponent(issuer || 'Observant-Website');
      const encodedUsername = encodeURIComponent(username);
      const totpUri = `otpauth://totp/${encodedIssuer}:${encodedUsername}?secret=${secretKey}&issuer=${encodedIssuer}`;
      setQrCode(totpUri);
      setShowTotpSetup(true);
    } catch (error) {
      console.error('Error setting up TOTP', error);
      setError('Failed to set up TOTP: ' + error.message);
    }
  };

  const handleVerifyTotp = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      await verifyTOTPSetup({
        userPoolId: awsExports.aws_user_pools_id,
        username: user.username,
        code: verificationCode,
      });
  
      await updateUserAttributes({
        userAttributes: {
          'custom:mfa_preference': 'totp',
        }
      });
  
      setMfaEnabled(true);
      setShowTotpSetup(false);
      alert('TOTP MFA set up successfully');
    } catch (error) {
      console.error('Error verifying TOTP:', error);
      setError('Failed to verify TOTP. Please try again. ' + error.message);
    }
  };

  if (loading) {
    return <div className="container mx-auto px-6 py-12">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-8">Account Management</h1>
      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Profile Information</h2>
        <form onSubmit={handleUpdateProfile}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={userAttributes.name || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={userAttributes.email || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="organization" className="block mb-2">Organization</label>
            <input
              type="text"
              id="organization"
              name="custom:organization"
              value={userAttributes['custom:organization'] || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="position" className="block mb-2">Position</label>
            <input
              type="text"
              id="position"
              name="custom:position"
              value={userAttributes['custom:position'] || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
            Update Profile
          </button>
        </form>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Security Settings</h2>
        <div className="flex items-center justify-between">
          <span>Multi-Factor Authentication (TOTP)</span>
          <button
            onClick={handleToggleMfa}
            className={`py-2 px-4 rounded ${mfaEnabled ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} text-white transition duration-300`}
          >
            {mfaEnabled ? 'Disable MFA' : 'Enable MFA'}
          </button>
        </div>

        {showTotpSetup && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">Set up Two-Factor Authentication</h3>
            
            {totpCode && (
              <div className="mb-4">
                <p className="font-semibold">TOTP Code for 1Password or other password managers:</p>
                <p className="font-mono bg-gray-100 p-2 rounded select-all">{totpCode}</p>
                <p className="text-sm text-gray-600 mt-1">
                  Copy this code and paste it into your password manager's TOTP field.
                </p>
              </div>
            )}
            
            <p className="mb-4">Or scan this QR code with your authenticator app:</p>
            
            {qrCode && <QRCodeSVG value={qrCode} size={200} className="mb-4" />}
            
            <form onSubmit={handleVerifyTotp}>
            <div className="mb-4">
  <p className="font-semibold">Or enter this secret key manually:</p>
  <p className="font-mono bg-gray-100 p-2 rounded select-all">{totpSecret}</p>
  <p className="text-sm text-gray-600 mt-1">
    Enter this secret key into your authenticator app if you can't scan the QR code.
  </p>
</div>
              <div className="mb-4">
                <label htmlFor="verificationCode" className="block mb-2">Enter the 6-digit code from your authenticator app:</label>
                <input
                  type="text"
                  id="verificationCode"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                  maxLength="6"
                  pattern="\d{6}"
                />
              </div>
              <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                Verify and Enable MFA
              </button>
            </form>
          </div>
        )}

        {error && <p className="text-red-500 mt-4">{error}</p>}

        <button onClick={signOut} className="mt-8 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300">
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default withAuthenticator(AccountManagement);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}