import ApiService from './api-service';

class EndpointService {
  static async getAllEndpoints() {
    try {
      const response = await ApiService.get('/endpoint');
      console.log('Endpoints data:', response);
      return Array.isArray(response) ? response : [];
    } catch (error) {
      console.error('Error in getAllEndpoints:', error);
      throw error;
    }
  }

  static async getEndpoint(endpointId) {
    return ApiService.get(`/endpoint/${endpointId}`);
  }

  static async createEndpoint(data) {
    return ApiService.post('/endpoint', data);
  }

  static async updateEndpoint(endpointId, data) {
    return ApiService.put(`/endpoint/${endpointId}`, data);
  }

  static async deleteEndpoint(endpointId) {
    return ApiService.delete(`/endpoint/${endpointId}`);
  }

  static async bulkAction(action, endpointIds) {
    return ApiService.post('/endpoint/bulk', { action, endpointIds });
  }
}

export default EndpointService;