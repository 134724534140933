import { useState, useCallback } from 'react';
import EndpointService from '../services/endpoint-service';

export const useEndpoint = () => {
  const [endpoints, setEndpoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchEndpoints = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await EndpointService.getAllEndpoints();
      // Ensure we always have an array
      setEndpoints(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching endpoints:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  const createEndpoint = useCallback(async (endpointData) => {
    setLoading(true);
    setError(null);
    try {
      const newEndpoint = await EndpointService.createEndpoint(endpointData);
      setEndpoints(prev => [...prev, newEndpoint]);
      return newEndpoint;
    } catch (err) {
      setError(err.message);
      console.error('Error creating endpoint:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateEndpoint = useCallback(async (endpointId, endpointData) => {
    setLoading(true);
    setError(null);
    try {
      const updatedEndpoint = await EndpointService.updateEndpoint(endpointId, endpointData);
      setEndpoints(prev => prev.map(endpoint => 
        endpoint.id === endpointId ? updatedEndpoint : endpoint
      ));
      return updatedEndpoint;
    } catch (err) {
      setError(err.message);
      console.error('Error updating endpoint:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteEndpoint = useCallback(async (endpointId) => {
    setLoading(true);
    setError(null);
    try {
      await EndpointService.deleteEndpoint(endpointId);
      setEndpoints(prev => prev.filter(endpoint => endpoint.id !== endpointId));
    } catch (err) {
      setError(err.message);
      console.error('Error deleting endpoint:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const performBulkAction = useCallback(async (action, endpointIds) => {
    setLoading(true);
    setError(null);
    try {
      await EndpointService.bulkAction(action, endpointIds);
      await fetchEndpoints(); // Refresh the list after bulk action
    } catch (err) {
      setError(err.message);
      console.error('Error performing bulk action:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchEndpoints]);

  return {
    endpoints,
    loading,
    error,
    fetchEndpoints,
    createEndpoint,
    updateEndpoint,
    deleteEndpoint,
    performBulkAction
  };
};

export default useEndpoint;