import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useEndpoint from '../hooks/useEndpoint';
import { 
  AlertCircle, 
  Search,
  MoreVertical,
  Clock,
  Settings,
  Trash2
} from 'lucide-react';

const ManageEndpoints = () => {
  const { endpoints, loading, error, fetchEndpoints, deleteEndpoint } = useEndpoint();
  const [filterType, setFilterType] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchEndpoints();
  }, [fetchEndpoints]);

  const filteredEndpoints = endpoints.filter(endpoint => {
    const matchesSearch = endpoint.endpoint_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         endpoint.endpoint_description?.toLowerCase().includes(searchQuery.toLowerCase());
    
    if (filterType === 'all') return matchesSearch;
    if (filterType === 'up') return matchesSearch && endpoint.up_dn_status === 'UP';
    if (filterType === 'down') return matchesSearch && endpoint.up_dn_status === 'DOWN';
    if (filterType === 'disabled') return matchesSearch && !endpoint.is_enabled;
    
    return matchesSearch;
  });

  const getStatusBadge = (status, enabled) => {
    if (!enabled) {
      return <span className="px-2 py-1 bg-gray-200 text-gray-700 rounded-full text-sm">Disabled</span>;
    }
    if (status === 'UP') {
      return <span className="px-2 py-1 bg-green-500 text-white rounded-full text-sm">Up</span>;
    }
    if (status === 'DOWN') {
      return <span className="px-2 py-1 bg-red-500 text-white rounded-full text-sm">Down</span>;
    }
    return <span className="px-2 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Unknown</span>;
  };

  const stats = {
    total: endpoints.length,
    up: endpoints.filter(e => e.up_dn_status === 'UP').length,
    down: endpoints.filter(e => e.up_dn_status === 'DOWN').length,
    disabled: endpoints.filter(e => !e.is_enabled).length
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return 'Never';
    return new Date(timestamp * 1000).toLocaleString();
  };

  const handleDeleteEndpoint = async (endpointId) => {
    if (window.confirm('Are you sure you want to delete this endpoint?')) {
      try {
        await deleteEndpoint(endpointId);
        fetchEndpoints();
      } catch (err) {
        console.error('Failed to delete endpoint:', err);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Manage Endpoints</h1>
        <Link 
          to="/add-endpoint" 
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
        >
          Add New Endpoint
        </Link>
      </div>

      {/* Stats Summary */}
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="text-sm text-gray-500 dark:text-gray-400">Total Endpoints</div>
          <div className="text-2xl font-bold">{stats.total}</div>
        </div>
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="text-sm text-green-500">Up</div>
          <div className="text-2xl font-bold">{stats.up}</div>
        </div>
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="text-sm text-red-500">Down</div>
          <div className="text-2xl font-bold">{stats.down}</div>
        </div>
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
          <div className="text-sm text-gray-500">Disabled</div>
          <div className="text-2xl font-bold">{stats.disabled}</div>
        </div>
      </div>

      {/* Controls */}
      <div className="flex gap-4 items-center mb-6">
        <div className="flex-1">
          <input
            type="search"
            placeholder="Search endpoints..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700"
          />
        </div>
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="px-4 py-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700"
        >
          <option value="all">All Status</option>
          <option value="up">Up Only</option>
          <option value="down">Down Only</option>
          <option value="disabled">Disabled</option>
        </select>
      </div>

      {/* Endpoints Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredEndpoints.map((endpoint) => (
          <div key={endpoint.endpoint_id} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="font-semibold text-lg">{endpoint.endpoint_name}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {endpoint.endpoint_type}
                </p>
              </div>
              <div className="flex items-center gap-2">
                {getStatusBadge(endpoint.up_dn_status, endpoint.is_enabled)}
                <div className="relative group">
                  <button className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded">
                    <MoreVertical className="h-4 w-4" />
                  </button>
                  <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg py-1 hidden group-hover:block">
                    <Link 
                      to={`/edit-endpoint/${endpoint.endpoint_id}`}
                      className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <Settings className="mr-2 h-4 w-4" /> Edit
                    </Link>
                    <button
                      onClick={() => handleDeleteEndpoint(endpoint.endpoint_id)}
                      className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <Trash2 className="mr-2 h-4 w-4" /> Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="space-y-2 text-sm">
              <div className="flex items-center text-gray-600 dark:text-gray-300">
                <Clock className="h-4 w-4 mr-2" />
                Last Check: {formatDateTime(endpoint.last_checked_dt)}
              </div>
              <div className="flex items-center text-gray-600 dark:text-gray-300">
                <AlertCircle className="h-4 w-4 mr-2" />
                Last Down: {formatDateTime(endpoint.last_downtime_dt)}
              </div>
            </div>

            {endpoint.last_result && (
              <div className="mt-4 p-2 bg-gray-50 dark:bg-gray-900 rounded text-xs font-mono truncate">
                {endpoint.last_result}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageEndpoints;