import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setUpTOTP, verifyTOTPSetup } from '@aws-amplify/auth'; // Updated import
import { QRCodeSVG } from 'qrcode.react';

const MfaSetup = () => {
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totpSecretKey, setTotpSecretKey] = useState(''); // New state to store TOTP secret key
  const navigate = useNavigate();
  const location = useLocation();
  const { username, mfaPreference } = location.state || {};

  useEffect(() => {
    if (mfaPreference === 'totp') {
      setupTOTP();
    }
  }, [mfaPreference]);

  const setupTOTP = async () => {
    try {
      const { totpIssuer, totpUsername, secretKey } = await setUpTOTP();
      const totpUri = `otpauth://totp/${totpIssuer}:${totpUsername}?secret=${secretKey}&issuer=${totpIssuer}`;
      setQrCode(totpUri);
      setTotpSecretKey(secretKey); // Store the secret key
    } catch (error) {
      console.error('Error setting up TOTP:', error);
      setError('Failed to set up Google Authenticator. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (mfaPreference === 'totp') {
        await verifyTOTPSetup({
          userPoolId: 'YOUR_USER_POOL_ID', // Replace with your actual User Pool ID
          username,
          code: verificationCode,
          secretKey: totpSecretKey,
        });
      } else if (mfaPreference === 'email') {
        // Implement email MFA verification here
        // This might involve sending a verification code to the user's email
        // and then verifying it with your backend
        console.log('Email MFA verification not implemented yet');
      }

      navigate('/dashboard');
    } catch (err) {
      console.error('MFA setup error:', err);
      setError(err.message || 'Failed to set up MFA. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-6 py-12 bg-white dark:bg-gray-900 text-black dark:text-white">
      <h1 className="text-4xl font-bold mb-8">Set Up MFA</h1>
      <div className="max-w-md mx-auto">
        {mfaPreference === 'totp' && (
          <>
            <p className="mb-4 dark:text-gray-300">Scan this QR code with your Google Authenticator app:</p>
            {qrCode && <QRCodeSVG value={qrCode} size={200} className="mb-6 mx-auto" />}
          </>
        )}
        {mfaPreference === 'email' && (
          <p className="mb-4 dark:text-gray-300">Enter the verification code sent to your email:</p>
        )}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="verificationCode" className="block mb-2 dark:text-gray-300">Verification Code</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify and Complete Setup'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MfaSetup;