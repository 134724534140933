import React from 'react';

const Pricing = () => {
  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">Choose Your Perfect Plan</h1>
      <p className="text-xl text-center mb-12 text-gray-700 dark:text-gray-300">Get started with Observant today and enjoy 5% savings compared to other providers!</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <PricingCard 
          title="Pay per endpoint with single region" 
          price="$2"
          description="Single endpoint, single region monitoring"
          features={[
            'Monitor 1 endpoint from 1 region',
            'As low as 60-second check interval',
            'Monitoring from 20+ global locations',
            'Geo-specific performance insights',
            'Advanced network diagnostics',
            'Custom alert routing by region'
          ]}
        />
        <PricingCard 
          title="Pay per endpoint with 2 regions" 
          price="$3"
          description="Single endpoint, 2 region monitoring"
          features={[
            'Monitor up to 5 endpoints',
            '2-minute check interval',
            'Email & SMS alerts',
            'Basic uptime reports',
            '24/7 email support'
          ]}
        />
        <PricingCard 
          title="Professional" 
          price="$18"
          description="Ideal for growing businesses and e-commerce sites"
          features={[
            'Monitor up to 20 endpoints',
            '1-minute check interval',
            'Email, SMS & voice call alerts',
            'Detailed performance reports',
            'API access',
            '24/7 priority support'
          ]}
          highlighted={true}
        />
        <PricingCard  
          title="Business" 
          price="$45"
          description="For large-scale applications and high-traffic websites"
          features={[
            'Monitor up to 50 endpoints',
            'up to 1-minute check interval',
            'Advanced alerting options',
            'Real-time performance analytics',
            'Custom metrics & integrations',
          ]}
        />
        <PricingCard 
          title="Business with 2 Zones" 
          price="$189.95"
          description="For developers and businesses requiring API monitoring"
          features={[
            'Monitor up to 200 API endpoints',
            'As low as 30-second check interval',
            'Advanced API testing & validation',
            'Performance trend analysis',
            'Customizable error handling',
            'Developer-friendly documentation'
          ]}
        />
        <PricingCard 
          title="Global" 
          price="$284.95"
          description="Worldwide monitoring from multiple locations"
          features={[
            'Monitor up to 150 endpoints',
            'As low as 30-second check interval',
            'Monitoring from 20+ global locations',
            'Geo-specific performance insights',
            'Advanced network diagnostics',
            'Custom alert routing by region'
          ]}
        />
      </div>
    </div>
  );
};

const PricingCard = ({ title, price, description, features, highlighted = false }) => {
  return (
    <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 ${highlighted ? 'border-4 border-blue-500' : ''}`}>
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-2 text-gray-900 dark:text-white">{title}</h3>
        <p className="text-4xl font-bold mb-4 text-blue-600 dark:text-blue-400">{price}<span className="text-sm font-normal text-gray-600 dark:text-gray-400">/month</span></p>
        <p className="text-gray-600 dark:text-gray-400 mb-6">{description}</p>
        <ul className="mb-8 space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-gray-700 dark:text-gray-300">
              <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="px-6 pb-6">
        <button className={`w-full py-2 px-4 rounded transition duration-300 ${
          highlighted 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-gray-200 text-gray-800 hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600'
        }`}>
          Choose Plan
        </button>
      </div>
    </div>
  );
};

export default Pricing;