import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEndpoint from '../hooks/useEndpoint';

const AddEndpoint = () => {
  const navigate = useNavigate();
  const { createEndpoint } = useEndpoint();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    type: 'http',
    url: '',
    method: 'GET',
    interval: 60,
    timeout: 30,
    retries: 3,
    headers: '',
    body: '',
    expected_status_code: 200,
    expected_response_contains: '',
    monitoring_locations: ['us-east-1'],
    alert_threshold: 2,
    enabled: true
  });

  const endpointTypes = [
    { value: 'http', label: 'HTTP/HTTPS' },
    { value: 'icmp', label: 'ICMP (Ping)' },
    { value: 'tcp', label: 'TCP Port' },
    { value: 'ssh', label: 'SSH' },
    { value: 'ftp', label: 'FTP' },
    { value: 'smtp', label: 'SMTP' },
    { value: 'dns', label: 'DNS' }
  ];

  const httpMethods = ['GET', 'POST', 'PUT', 'DELETE', 'HEAD', 'OPTIONS'];

  const regions = [
    { value: 'us-east-1', label: 'US East (N. Virginia)' },
    { value: 'us-west-2', label: 'US West (Oregon)' },
    { value: 'eu-central-1', label: 'EU (Frankfurt)' },
    { value: 'ap-southeast-2', label: 'Asia Pacific (Sydney)' }
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleRegionChange = (e) => {
    const value = Array.from(e.target.selectedOptions, option => option.value);
    setFormData(prev => ({
      ...prev,
      monitoring_locations: value
    }));
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setError('Name is required');
      return false;
    }
    if (!formData.url.trim()) {
      setError('URL is required');
      return false;
    }
    if (formData.type === 'http' && !formData.url.match(/^https?:\/\/.+/)) {
      setError('Invalid URL format. Must start with http:// or https://');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const endpointData = {
        ...formData,
        headers: formData.headers ? JSON.parse(formData.headers) : {},
        expected_status_code: parseInt(formData.expected_status_code),
        interval: parseInt(formData.interval),
        timeout: parseInt(formData.timeout),
        retries: parseInt(formData.retries),
        alert_threshold: parseInt(formData.alert_threshold)
      };

      await createEndpoint(endpointData);
      navigate('/endpoints');
    } catch (err) {
      setError(err.message || 'Failed to create endpoint');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 dark:text-white">Add New Endpoint</h1>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="col-span-2">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">Type</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {endpointTypes.map(type => (
                <option key={type.value} value={type.value}>{type.label}</option>
              ))}
            </select>
          </div>

          {formData.type === 'http' && (
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Method</label>
              <select
                name="method"
                value={formData.method}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {httpMethods.map(method => (
                  <option key={method} value={method}>{method}</option>
                ))}
              </select>
            </div>
          )}

          <div className="col-span-2">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">URL</label>
            <input
              type="text"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
              placeholder="https://example.com"
            />
          </div>

          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">Interval (seconds)</label>
            <input
              type="number"
              name="interval"
              value={formData.interval}
              onChange={handleChange}
              min="60"
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-gray-700 dark:text-gray-300 mb-2">Monitoring Locations</label>
            <select
              name="monitoring_locations"
              multiple
              value={formData.monitoring_locations}
              onChange={handleRegionChange}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              size="4"
            >
              {regions.map(region => (
                <option key={region.value} value={region.value}>{region.label}</option>
              ))}
            </select>
          </div>

          {formData.type === 'http' && (
            <>
              <div className="col-span-2">
                <label className="block text-gray-700 dark:text-gray-300 mb-2">Headers (JSON)</label>
                <textarea
                  name="headers"
                  value={formData.headers}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder='{"Authorization": "Bearer token"}'
                  rows="3"
                />
              </div>

              <div>
                <label className="block text-gray-700 dark:text-gray-300 mb-2">Expected Status Code</label>
                <input
                  type="number"
                  name="expected_status_code"
                  value={formData.expected_status_code}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>

              <div>
                <label className="block text-gray-700 dark:text-gray-300 mb-2">Expected Response Contains</label>
                <input
                  type="text"
                  name="expected_response_contains"
                  value={formData.expected_response_contains}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            </>
          )}

          <div className="col-span-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="enabled"
                checked={formData.enabled}
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="text-gray-700 dark:text-gray-300">Enable monitoring</span>
            </label>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/endpoints')}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {loading ? 'Creating...' : 'Create Endpoint'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEndpoint;